import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <NotFound.Wrapper>
      <h1>Oops! You've hit a route that doesn't exist</h1>
      <Link to="/login">Go back to Login page</Link>
    </NotFound.Wrapper>
  );
};

export default NotFound;
NotFound.Wrapper = styled(Box)`
  text-align: center;
`;
