import { Dialog, DialogContent } from "@mui/material";
import { CustomText } from "common/Reusables";
import NoInternet from "assets/icons/no-network.svg";
import React from "react";

const NoInternetModal = ({ visible }) => {
  return (
    <Dialog
      open={visible}
      aria-labelledby="no-internet-dialog"
      disableEscapeKeyDown
    >
      <DialogContent
        sx={{
          textAlign: "center",
        }}
      >
        <img src={NoInternet} alt="no internet icon" />
        <CustomText fontSize="24px" fontWeight="600" color="#545454">
          No internet Connection
        </CustomText>
        <CustomText marginTop="10px" fontSize="16px" color="#545454">
          Please check your internet connection and try again.
        </CustomText>
      </DialogContent>
    </Dialog>
  );
};

export default NoInternetModal;
