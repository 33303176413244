import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = "https://chat.coolowo.com/";
const access_token = localStorage.getItem("access_token");
// export const socket = io(URL, {
//   transports: ["polling"],
//   reconnectionAttempts: 10,
//   reconnectionDelay: 1000,
//   reconnection: true,
//   pingInterval: 25000, // How often to send a ping packet
//   pingTimeout: 20000,
//   extraHeaders: {
//     Authorization: `Bearer ${access_token}`,
//   },
// });
export const socket = io(URL, {
  reconnection: true,
  transports: ["polling"],
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  pingInterval: 10000,
  pingTimeout: 5000,
  extraHeaders: {
        Authorization: `Bearer ${access_token}`,
      },
});
