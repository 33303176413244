import { Box } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { getMethod } from "hooks/getHook";
import { SnackbarProvider } from "notistack";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { onBackgroundMessage, onMessageListener } from "./firebase";
import { ThemeProvider } from "styled-components";
import { PayrollProvider } from "./api/AuthContext";
import { clarity } from "react-microsoft-clarity";
import notificationSound from "./assets/notification-sound.mp3";
import ProtectedRoutes from "./components/ProtecctedRoutes";
import NotFound from "./Pages/UnAuthPages/404";
import appTheme from "./theme";
import PlanRestriction from "common/PlanRestriction";
import useOnlineStatus from "hooks/useOnlineStatus";
import useInvalidateQueries from "hooks/useInvalidateQueries";
import NoInternetModal from "Pages/AuthPages/Tasks/components/NoInternetModal";
import useHeapTracking from "hooks/useHeapTracking";

const theme = createTheme(appTheme);
const LoginPage = lazy(() => import("./Pages/UnAuthPages"));
const PasswordPage = lazy(() => import("./Pages/UnAuthPages/PasswordSent"));
const SetPasswordPage = lazy(() =>
  import("./Pages/UnAuthPages/SetPasswordReset")
);

const AuthScreen = lazy(() =>
  import("./Pages/UnAuthPages/Onboarding/AuthScreen")
);
const AuthNavBar = lazy(() => import("./components/AuthNavBar"));
const OnboardSuccess = lazy(() =>
  import("./Pages/UnAuthPages/Onboarding/Success")
);
const VerifyTokenPage = lazy(() => import("./Pages/UnAuthPages/VerifyToken"));
const ExistingUserSignup = lazy(() =>
  import("./Pages/UnAuthPages/ExistingUser/index.jsx")
);
const FloatingButton = lazy(() => import("components/Chat/FloatingButton"));
const Notification = lazy(() => import("components/Chat/Notification"));
const ChatDrawer = lazy(() =>
  import("Pages/AuthPages/Dashboard/Chat/ChatDrawer")
);
const MobileSignUpPage = lazy(() => import("./Pages/UnAuthPages/SignupMobile"));
const RedirectToApp = lazy(() => import("./Pages/UnAuthPages/RedirectToApp"));
const SignupPage = lazy(() => import("./Pages/UnAuthPages/SignUp"));
const TimeoffPage = lazy(() => import("./Pages/AuthPages/Timeoff"));
const DocumentsPage = lazy(() => import("./Pages/AuthPages/Documents"));
const ProfilePage = lazy(() => import("./Pages/AuthPages/Profile"));
const PeoplePage = lazy(() => import("./Pages/AuthPages/People"));
const PayslipPage = lazy(() => import("./Pages/AuthPages/Payslips"));
const PayslipDetails = lazy(() => import("./Pages/AuthPages/Payslips/payslip"));
const BenefitPage = lazy(() => import("./Pages/AuthPages/Benefits"));
const TrainingPage = lazy(() => import("./Pages/AuthPages/Training"));
const SettingsPage = lazy(
  () => import("./Pages/AuthPages/Performance/Settings/routes.settings")
  // const SettingsPage = lazy(() =>
  //   import("./Pages/AuthPages/Performance/Settings")
);
const Visibility = lazy(() =>
  import("./Pages/AuthPages/Performance/Settings/Visibility")
);
const Security = lazy(() =>
  import("./Pages/AuthPages/Performance/Settings/Security")
);

const TasksPage = lazy(() => import("./Pages/AuthPages/Tasks/routes.tasks"));
const ResetPasswordPage = lazy(() =>
  import("./Pages/UnAuthPages/ResetPassword")
);
const PerformancePage = lazy(() =>
  import("./Pages/AuthPages/Performance/routes.performance")
);
const routesWithoutSideNav = [
  "/",
  "/login",
  "/signup",
  "/signup-mobile",
  "/forgot-password",
  "/404",
  "/verify-token",
  "/forgot-password-confirm",
  "/account/password-reset",
  "/mobile-app-redirect",
  "/existing-user-signup",
  "/onboard/employee",
  "/onboard/success",
];
const Dashboard = lazy(() => import("./Pages/AuthPages/Dashboard"));
const AttendancePage = lazy(() => import("./Pages/AuthPages/Attendance"));
const ShiftManagement = lazy(() => import("./Pages/AuthPages/ShiftManagement"));
function App() {
  const domain = localStorage.getItem("business_id");
  // useFreshChat();
  function useSearchQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const searchQuery = useSearchQuery();
  const { pathname } = useLocation();
  const hideSideNav = routesWithoutSideNav.includes(pathname);

  const { data: userInformation, isSuccess } = useQuery(
    [
      "",
      {
        route: `/employees/me/`,
        params: {},
      },
    ],
    getMethod,
    {
      enabled: !hideSideNav,
    }
  );

  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    if (searchQuery.get("open") === "chat" && isSuccess) {
      setOpenChat(true);
    }
  }, [searchQuery, isSuccess]);

  const [notification, setNotification] = useState({
    title: "",
    body: "",
    image: "",
  });
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const audio = new Audio(notificationSound);

    const playNotificationSound = () => {
      audio
        .play()
        .catch((error) => console.error("Error playing sound:", error));
    };

    onMessageListener()
      .then((payload) => {
        console.log("Received foreground notification:", payload);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
          image: payload.notification.image,
        });
        setIsShow(true);
        playNotificationSound();
      })
      .catch((err) =>
        console.log("Failed to receive foreground message: ", err)
      );

    onBackgroundMessage((payload) => {
      console.log("Received background message in app:", payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        image: payload.notification.image,
      });
      setIsShow(true);
      playNotificationSound();
    });

    // Cleanup if needed
  }, [notification]);

  useEffect(() => {
    let timer;
    if (!hideSideNav && isShow) {
      timer = setTimeout(() => {
        setIsShow(false);
      }, 15000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isShow, hideSideNav]);

  const [isModalVisible, setModalVisible] = useState(false);

  const isOnline = useOnlineStatus();
  const { invalidateAllQueries } = useInvalidateQueries();

  useEffect(() => {
    if (isOnline) {
      setModalVisible(false);
      invalidateAllQueries();
    } else {
      setModalVisible(true);
    }
  }, [isOnline, invalidateAllQueries]);

  useHeapTracking(userInformation, domain);
  return (
    <Box>
      <Suspense fallback={<></>}>
        {isShow && !hideSideNav ? (
          <Notification {...{ notification, isShow, setIsShow }} />
        ) : null}
      </Suspense>
      <Suspense fallback={<></>}>
        <NoInternetModal visible={isModalVisible} />
      </Suspense>
      {process.env.REACT_APP_ENV === "prod" && clarity.init("pur4602ir8")}

      {/* {isShow && !hideSideNav ? (
        <Notification {...{ notification, isShow, setIsShow }} />
      ) : null} */}
      {/* {process.env.REACT_APP_ENV === "prod" ? (
        <Heap
          appId="2856311930"
          userId={userInformation?.email}
          userData={{
            Business_ID: domain,
            email: userInformation?.email,
            hire_date: userInformation?.hire_date,
            employee_id: userInformation?.employee_id,
            Bussiness_Name: userInformation?.business_name,
          }}
        />
      ) : (
        <Heap
          appId="2684401632"
          userId={userInformation?.email}
          userData={{
            Business_ID: domain,
            email: userInformation?.email,
            hire_date: userInformation?.hire_date,
            employee_id: userInformation?.employee_id,
            Bussiness_Name: userInformation?.business_name,
          }}
        />
      )} */}
      <>
        <PayrollProvider isAuthRequireedPages={!hideSideNav}>
          <PlanRestriction />
          <SnackbarProvider />
          <Switch>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      {" "}
                      <PropagateLoader color="#2898A4" />
                    </Box>
                  }
                >
                  {!hideSideNav && <AuthNavBar />}
                  <Switch>
                    <Route exact path="/" component={LoginPage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/signup" component={SignupPage} />

                    <Route
                      exact
                      path="/verify-token"
                      component={VerifyTokenPage}
                    />
                    <Route
                      exact
                      path="/signup-mobile"
                      component={MobileSignUpPage}
                    />
                    <Route
                      exact
                      path="/account/password-reset"
                      component={SetPasswordPage}
                    />
                    <Route
                      exact
                      path="/mobile-app-redirect"
                      component={RedirectToApp}
                    />

                    <Route
                      exact
                      path="/forgot-password-confirm"
                      component={PasswordPage}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ResetPasswordPage}
                    />

                    <Route
                      exact
                      path="/onboard/employee"
                      component={AuthScreen}
                    />
                    <Route
                      exact
                      path="/onboard/success"
                      component={OnboardSuccess}
                    />
                    <Route
                      exact
                      path="/existing-user-signup"
                      component={ExistingUserSignup}
                    />

                    <ProtectedRoutes
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <ProtectedRoutes exact path="/home" component={Dashboard} />
                    <ProtectedRoutes
                      exact
                      path="/shift"
                      component={ShiftManagement}
                    />
                    <ProtectedRoutes
                      exact
                      path="/settings"
                      component={SettingsPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/people"
                      component={PeoplePage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/documents"
                      component={DocumentsPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/settings/visibilty"
                      component={Visibility}
                    />
                    <ProtectedRoutes
                      exact
                      path="/settings/change-password"
                      component={Security}
                    />
                    <ProtectedRoutes
                      exact
                      path="/timeoffs"
                      component={TimeoffPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/profile"
                      component={ProfilePage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payroll/:date/:id"
                      component={PayslipDetails}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payslips"
                      component={PayslipPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/benefits"
                      component={BenefitPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/attendance"
                      component={AttendancePage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trainings"
                      component={TrainingPage}
                    />
                    <ProtectedRoutes path="/tasks" component={TasksPage} />
                    <ProtectedRoutes
                      path="/performance"
                      component={PerformancePage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/new-dashboard"
                      component={Dashboard}
                    />
                    <Route path="/404" exact>
                      <NotFound />
                    </Route>
                    <Route path="*">
                      <Redirect to="/404" />
                    </Route>
                  </Switch>

                  {!hideSideNav && userInformation?.id && (
                    <FloatingButton {...{ setOpenChat, userInformation }} />
                  )}
                  {!hideSideNav ? (
                    <ChatDrawer
                      {...{
                        openChat,
                        setOpenChat,
                      }}
                    />
                  ) : null}
                </Suspense>
              </ThemeProvider>
            </MuiThemeProvider>
          </Switch>
        </PayrollProvider>
      </>
      {/* )} */}
    </Box>
  );
}

export default App;
