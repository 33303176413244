import { useEffect } from "react";

const useHeapTracking = (userInformation, domain) => {
  useEffect(() => {
    if (userInformation?.email) {
      window.heap.identify(userInformation.email);
      window.heap.addUserProperties({
        Business_ID: domain,
        email: userInformation?.email,
        hire_date: userInformation?.hire_date,
        employee_id: userInformation?.employee_id,
        Business_Name: userInformation?.business_name,
      });
    }
  }, [userInformation, domain]);
};

export default useHeapTracking;
