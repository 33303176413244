import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton,Menu,MenuItem } from "@mui/material";
import { isFunc } from "common/utils";
import { sizes } from "../Typography/Text";

export const customMenuPositions = {
  tl: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
  tr: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
  br: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
  bl: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
  b: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
  c: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "center",
    },
  },
};

export default function CustomMenu({
  options = [],
  AnchorEl,
  divideAll,
  size,
  style,
  shadow,
  width = 300,
  innerWidth = 200,
  position = customMenuPositions.tl,
  rounded,
  stopPropagation,

  ...props
}) {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const menuId = `menu-${Math.random() * (options?.legth || 0)}`;
  return (
    <div>
      <div>
        {isFunc(AnchorEl) ? (
          AnchorEl({ handleOpen })
        ) : (
          <IconButton onClick={handleOpen}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={
          customMenuPositions[position]?.anchorOrigin ?? position.anchorOrigin
        }
        PaperProps={{
          style: {
            borderRadius: rounded || "5px", // Set your desired border radius value
          },
        }}
        transformOrigin={
          customMenuPositions[position]?.transformOrigin ??
          position.transformOrigin
        }
        {...props}
        sx={{
          marginTop: 1,
          width,
          padding: 0,
          "& ul": {
            paddingBlock: 0,
          },
          borderRadius: "100px",
          ...style,
        }}
        MenuListProps={{
          "aria-labelledby": "menu-tab",
          role: "tab-sub-list",
        }}
      >
        {options
          ?.filter((el) => el.label)
          ?.map((option, index) => (
            <MenuItem
              className={option?.className || ""}
              style={{
                width: innerWidth,
                paddingBlock: 10,
                borderBottom: divideAll ? "1px solid #E1E1E1" : "none",
                fontSize: sizes[size],
                color: "#545454",
                fontFamily: "var(--black-family)",
                fontWeight: 400,

                ...(option.isLoading
                  ? { display: "grid", gridTemplateColumns: "1fr 4fr" }
                  : {}),
                ...option?.style,
              }}
              key={option?.id ?? index}
              onClick={(event) => {
                if (stopPropagation) {
                  event.stopPropagation();
                }
                if (isFunc(option.onClick)) {
                  option?.onClick(event, (e) => handleClose(event));
                }
              }}
            >
              <>
                {option?.isLoading && <CircularProgress size={12} />}
                {option?.label}
              </>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
