import { useQueryClient } from 'react-query';

const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateAllQueries = () => {
    queryClient.invalidateQueries('tasks-table');
    queryClient.invalidateQueries('employee-tasks-table'); 
    queryClient.invalidateQueries('department-tasks-table'); 
    queryClient.invalidateQueries('project-tasks-table'); 
    queryClient.invalidateQueries('get-projects'); 
    queryClient.invalidateQueries(['assignee-employees']);
    queryClient.refetchQueries(["task-details"]);
    queryClient.refetchQueries(["task-comments"]);
    queryClient.refetchQueries(["tasks-activities"]);
  };

  return {
    invalidateAllQueries
  };
};

export default useInvalidateQueries;
